class ScanHowInputTextField extends React.Component{
    constructor(props){
        super(props)
        this.props = props;
        /*this.state = { missingProps : null, value:this.props.value};*/
        this.state = { missingProps : null };
        this.fieldUpdated = this.fieldUpdated.bind(this)
    }

    fieldUpdated(e){
        /*this.setState({value:e.target.value});*/
        if( this.props.onChange ){
            this.props.onChange(e);
        }
    }

    componentDidUpdate(){
        /*document.querySelector('.'+this.props.fieldName).MaterialTextfield.checkDirty();*/
        /*var element = document.querySelector('input[name="'+ this.props.fieldName + '"]');
        if( element && element.parentElement.MaterialTextfield ){
            element.parentElement.MaterialTextfield.checkDirty();
        }*/
        this.textInput.MaterialTextfield.checkDirty();
        componentHandler.upgradeDom();

    }

    componentDidMount(){
        /*var element = document.querySelector('input[name="'+ this.props.fieldName + '"]');
        if( element ){
            componentHandler.upgradeDom(element.parentElement);
        }*/
        componentHandler.upgradeDom();
    }

    render() {
        return (
            <div ref={(input) => { this.textInput = input; }} className={"mdl-textfield mdl-js-textfield scanhow-textfield mdl-textfield--floating-label "+this.props.customClass}>
                <input className="mdl-textfield__input" type={this.props.type} name={this.props.fieldName} value={this.props.value}
                       onChange={this.fieldUpdated} onKeyDown={this.props.keyDownHandler} disabled={this.props.disabled} maxLength={this.props.maxLength} onFocus={this.props.onFocus} onBlur={this.props.onBlur}/>
                <label className="mdl-textfield__label" htmlFor={this.props.fieldName}>{this.props.placeholderText}</label>
            </div>
        )
    }
}
// Specifies the default values for props:
ScanHowInputTextField.defaultProps = {
    value: '',
    customClass: '',
    disabled:false,
    type:'text',
};
ScanHowInputTextField.propTypes ={
    fieldName : React.PropTypes.string.isRequired,
    placeholderText : React.PropTypes.string.isRequired,
    onChange : React.PropTypes.func.isRequired,
    onFocus : React.PropTypes.func,
    onBlur : React.PropTypes.func,
    value:React.PropTypes.string.isRequired,
    customClass : React.PropTypes.string,
    disabled: React.PropTypes.bool,
    type:React.PropTypes.string,
    keyDownHandler: React.PropTypes.func,
    maxLength: React.PropTypes.number,
};




class ScanhowCheckbox extends React.Component{
    constructor(props){
        super(props);
        this.props = props
        this.onChange = this.onChange.bind(this);
    }

    onChange(e){
        console.log("E " +e);
    }

    componentDidUpdate(prevProps, prevState) {
        componentHandler.upgradeDom();
        /*console.log("ScanhowCheckbox didUpdate: " + this.props.checkBoxName);*/
        /*var mdlCheckbox = this.checkbox.MaterialCheckbox;*/
        if (prevProps.checked != this.props.checked) {
            if (this.props.checked) {
                this.checkbox.MaterialCheckbox.check();
            } else {
                this.checkbox.MaterialCheckbox.uncheck();
            }
        }
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }



    render(){
        var checkBoxId = DkScanHowHelpers.uniqueElementId(this.props.parentId, this.props.checkBoxName);
        /*console.log("ScanhowCheckbox render: " + checkBoxId);*/

        return (
            <label ref={(input) => { this.checkbox = input; }} className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect" htmlFor={checkBoxId}>
                <input type="checkbox" id={checkBoxId} name={this.props.checkBoxName} className="mdl-checkbox__input" defaultChecked={this.props.checked} onChange={this.props.onCheck}/>
                <span className="mdl-checkbox__label">{this.props.checkBoxText}</span>
            </label>
        );
    }
}
ScanhowCheckbox.defaultProps = {
};
ScanhowCheckbox.propTypes ={
    checkBoxName: React.PropTypes.string.isRequired,
    checkBoxText: React.PropTypes.object.isRequired,
    checked: React.PropTypes.bool.isRequired,
    parentId:React.PropTypes.string.isRequired,
    onCheck:React.PropTypes.func.isRequired
};

class ScanhowRadio extends React.Component{
    constructor(props){
        super(props);
        this.props = props
        this.onChange = this.onChange.bind(this);
    }

    onChange(e){
        console.log("E " +e);
        this.props.onCheck()
    }

    componentDidUpdate(prevProps, prevState) {
        componentHandler.upgradeDom();
        /*console.log("ScanhowCheckbox didUpdate: " + this.props.checkBoxName);*/
        /*var mdlCheckbox = this.checkbox.MaterialCheckbox;*/
        if (prevProps.checked != this.props.checked) {
            if (this.props.checked) {
                this.radio.MaterialRadio.check();
            } else {
                this.radio.MaterialRadio.uncheck();
            }
        }
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    render(){
        var radioId = DkScanHowHelpers.uniqueElementId(this.props.radioGroup, this.props.radioName);

        return (
        <label ref={(input) => { this.radio = input; }} className="mdl-radio mdl-js-radio mdl-js-ripple-effect" htmlFor={radioId}>
            <input className="mdl-radio__button" id={radioId} name={this.props.radioGroup} type="radio" onClick={this.props.onCheck} />
            <span className="mdl-radio__label">
                { this.props.radioText }
            </span>
        </label>
        );
    }
}
ScanhowRadio.defaultProps = {
};
ScanhowRadio.propTypes ={
    radioName: React.PropTypes.string.isRequired,
    radioText: React.PropTypes.element.isRequired,
    checked: React.PropTypes.bool.isRequired,
    onCheck:React.PropTypes.func.isRequired
};



class ScanHowButton extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
    }

    componentDidUpdate(prevProps, prevState){
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    render() {
        return (
            <button onClick={this.props.onClick} className={"mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect scanhowButton " +this.props.customClass} id={ this.props.id ? this.props.id : ""} disabled={this.props.isDisabled}>
                {this.props.text}
                {/*type={this.props.type ? 'submit' : ''}*/}
            </button>
        );
    }
}

ScanHowButton.propTypes ={
    text: React.PropTypes.string.isRequired,
    onClick: React.PropTypes.func,
    isDisabled:React.PropTypes.bool,
    id:React.PropTypes.string,
    customClass: React.PropTypes.string
};

/*
function ScanHowButton(props) {
    return (
        <button onClick={props.onClick} className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect" disabled={props.isDisabled}>
            {props.text}
        </button>
    );
}
*/




class ScanHowPicker extends React.Component{
    constructor(props){
        super(props);
        this.props = props;
        this.state = {newGroupName:"", childInputFocused: ""};
        this.createGroup = this.createGroup.bind(this);
        this.fieldUpdated = this.fieldUpdated.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.fieldFocused = this.fieldFocused.bind(this)
        this.fieldBlurred = this.fieldBlurred.bind(this)
    }

    createGroup(e){
        this.props.onAddItem( this.state.newGroupName );
    }

    fieldUpdated(e) {
        const key = e.target.name;
        const value = e.target.value;
        this.setState({[key]:value});
        /*console.log("fieldUpd: "+ name + " / " +value)*/
        /*        this.props.updateFormState(name, value);*/

    }

    fieldFocused(e){
        this.setState({childInputFocused: " focused"})
    }

    fieldBlurred(e){
        this.setState({childInputFocused: ""})
    }

    onCheckBoxChange(e){
        this.props.onToggleItem(e);
    }

    componentDidUpdate(prevProps, prevState){
        // This upgrades all upgradable components (i.e. with 'mdl-js-*' class)*/
        componentHandler.upgradeDom();
    }

    componentDidMount(){
        componentHandler.upgradeDom();
    }

    render(){
        var optionsArray = DkScanHowHelpers.objectToArray(this.props.options);

        var optionElements = null;

        if( optionsArray ) {
            optionElements = [];
            var selectedArray = DkScanHowHelpers.objectToArray(this.props.selectedItems);

            /*console.log("fieldName " + this.props.fieldName + " / " + this.props.parentId + " / " +JSON.stringify( this.props.selectedItems) );*/
            optionsArray.forEach( (option) => {
                var firstItem = DkScanHowHelpers.getFirstItemInArrayByValueOfChildKey(selectedArray, "key", option.groupKey);
                var isSelected = firstItem != undefined && firstItem.valueOf() == true;
                optionElements.push( <ScanhowCheckbox key={option.groupKey} checkBoxName={option.groupKey}
                                                    parentId={this.props.parentId} checkBoxText={option.groupName}
                                                    checked={isSelected} onCheck={this.onCheckBoxChange}/> );
            });
        }

        return(
            <div className={"dropdown"+this.state.childInputFocused}>
                <div className="dropbtn">
                    <ScanHowInputTextField fieldName={this.props.parentId+"seletedGroups"} placeholderText={DkScanHowLocalisation.t("web_components_picker_placeholder_groups")} value={DkScanHowHelpers.selectedGroupsAsString(this.props.selectedItems, this.props.options)} disabled={false} onChange={this.fieldUpdated} customClass="pickerHasValues"/>
                    <div className="dropdown-content">
                        <div className="dropdown-options">
                            { optionElements }
                        </div>
                        <div>
                            <div className="tableCell" ref={(newGroupField) => { this.newGroupField=newGroupField; }} >
                                <ScanHowInputTextField fieldName="newGroupName" value={this.state.newGroupName} placeholderText={this.props.addItemPlaceholderText} onChange={this.fieldUpdated} maxLength={16} onFocus={this.fieldFocused} onBlur={this.fieldBlurred}/>
                            </div>
                            <div className="tableCell">
                                <button className="mdl-button mdl-js-button mdl-button--icon " onClick={this.createGroup} disabled={this.state.newGroupName == ""}>
                                    <i className="material-icons">add</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
ScanHowPicker.defaultProps = {
    value: ''
};
ScanHowPicker.propTypes ={
    fieldName : React.PropTypes.string.isRequired,
    addItemPlaceholderText : React.PropTypes.string.isRequired,
    onAddItem : React.PropTypes.func.isRequired,
    onToggleItem : React.PropTypes.func.isRequired,
    selectedItems: React.PropTypes.object.isRequired,
    onChange : React.PropTypes.func,
    onFocus : React.PropTypes.func,
    onBlur : React.PropTypes.func,
    options:React.PropTypes.array.isRequired,
    parentId:React.PropTypes.string.isRequired
};